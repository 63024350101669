.resumedownloadbtn {
  display: inline-block;
  min-width: 150px;
  margin: 0px auto;
  background: rgb(0, 0, 0);
  color: #fefefe;
  font-size: 1.2em;
  padding: 15px 40px;
  border-radius: 4px;
  text-align: center;
  position: relative;
  cursor: pointer;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  border: 0;
  transition: border-radius linear 0.05s, width linear 0.05s;
}
@media (max-width: 480px) {
  .resumedownloadbtn {
    width: 95% !important;
    margin-top: 20px;
    margin-left: -5px;
  }
}
.resumedownloadbtn:focus {
  outline: 0;
}
.resumedownloadbtn.animate {
  width: 68.1818181818px;
  height: 68.1818181818px;
  min-width: 0;
  border-radius: 50%;
  color: transparent;
  padding: 1em !important;
}
.resumedownloadbtn.animate:after {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  border: 4px solid #fefefe;
  border-radius: 50%;
  border-left-color: transparent;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-animation: spin ease-in 2.5s forwards;
          animation: spin ease-in 2.5s forwards;
  animation-name: spin;
  -webkit-animation-name: spin;
  transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  animation-duration: 2.5s;
  -webkit-animation-duration: 2.5s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}
.resumedownloadbtn.animate.success:before {
  position: absolute;
  content: "";
  width: 25px;
  height: 12.5px;
  border: 4px solid #fefefe;
  border-right: 0;
  border-top: 0;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%) rotate(0deg) scale(0);
  -webkit-animation: success ease-in 0.15s forwards;
  animation: success ease-in 0.15s forwards;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}
.resumedownloadbtn.animate.error {
  position: relative;
  -webkit-animation: vibrate ease-in 0.5s forwards;
  animation: vibrate ease-in 0.5s forwards;
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
}
.resumedownloadbtn.animate.error:before {
  color: #fff;
  position: absolute;
  content: "!";
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  -webkit-animation: error ease-in 0.5s forwards;
  animation: error ease-in 0.5s forwards;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  90% {
    transform: translate(-50%, -50%) rotate(1080deg) scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
  }
  98% {
    -webkit-transform: translate(-50%, -50%) rotate(1080deg) scale(1);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(1080deg) scale(0);
  }
}
@keyframes success {
  from {
    transform: translate(-50%, -50%) rotate(0) scale(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
  }
}
@-webkit-keyframes success {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg) scale(1);
  }
}
@keyframes error {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
    background-color: #f44336;
  }
}
@-webkit-keyframes error {
  from {
    -webkit-transform: translate(-50%, -50%) scale(0);
  }
  to {
    -webkit-transform: translate(-50%, -50%) scale(1);
    background-color: #f44336;
  }
}
@-webkit-keyframes vibrate {
  0%, 30%, 60%, 85%, 100% {
    left: 0;
    background-color: #f44336;
  }
  10%, 40%, 90%, 70% {
    left: -2px;
    background-color: #f44336;
  }
  20%, 50%, 80%, 95% {
    left: 2px;
    background-color: #f44336;
  }
}
@keyframes vibrate {
  0%, 30%, 60%, 85%, 100% {
    left: 0;
    background-color: #f44336;
  }
  10%, 40%, 90%, 70% {
    left: -2px;
    background-color: #f44336;
  }
  20%, 50%, 80%, 95% {
    left: 2px;
    background-color: #f44336;
  }
}/*# sourceMappingURL=downloadbtn.css.map */