.adminSidebar{
    width: 100%;
    padding: 20px 0;
    .adminnavigation{
        width: 100%;
        div{
            color: var(--text-primary);
            display: flex;
            margin-bottom: 10px;
            &::before{
                content: '';
                display: block;
                height: 1px;
                width: 10px;
                background-color: #fff;
                margin-top: 12px;
            }
            span{
                margin-left: 5px;
            }
        }
        ul{
            width: 100%;
            li{
                width: calc(100% - 20%);
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                cursor: pointer;
                padding: 5px 10px;
                padding-left: 20px;
                border-radius: 5px;
                margin-bottom: 10px;
                .navIcs{
                    fill: var(--text-primary);
                    height: 18px;
                    width: 18px;
                    margin-right: 10px;
                    margin-top: 3px;
                }
                span{
                    color: var(--text-primary);
                    font-size: 1rem;
                }
                &:hover{
                    border: 1px solid var(--text-primary);
                    border-radius: 10px;
                    padding: 4px 9px;
                }
            }
        }
    }
}