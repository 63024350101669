.stats {
  min-height: 100vh;
  padding-top: 5rem;
}
.stats a .homeIcon {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 2%;
  left: 3%;
  fill: #fff;
  cursor: pointer;
}
.stats .statsWrapper .statsContainer {
  margin: 0 5%;
}
.stats .statsWrapper .statsContainer .statsFlex {
  position: relative;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile {
  height: 50vh;
  position: relative;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .sImage {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid #4ea9ff;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .sImage img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .suserTitle {
  text-align: center;
  color: var(--text-primary);
  font-size: 2rem;
  font-weight: 300;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .suserName {
  text-align: center;
  color: #4ea9ff;
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 200;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .suserName:hover {
  text-decoration: underline;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .suinfo {
  display: flex;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .suinfo .infotxts {
  color: var(--text-primary);
  font-size: 1.3rem;
  font-weight: 300;
  margin: 10px 20px;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .saccInfo {
  display: flex;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .saccInfo .sintx {
  margin: 10px 20px;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: rgba(36, 36, 36, 0.61);
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .saccInfo .sintx h2 {
  color: var(--text-primary);
  font-size: 2rem;
  text-align: center;
  margin-bottom: -5px;
  font-weight: 300;
}
.stats .statsWrapper .statsContainer .statsFlex .sprofile .snameTexts .saccInfo .sintx span {
  color: var(--text-primary);
  font-size: 0.8rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 200;
}
.stats .statsWrapper .statsContainer .statsFlex .dennisGraph {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: 0 auto !important;
}
.stats .statsWrapper .statsContainer .statsFlex .sactivity {
  display: flex;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin: 0 auto !important;
}/*# sourceMappingURL=stats.css.map */