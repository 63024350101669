.background {
  height: 100vh;
}
.background .bwrapper .bcontainer {
  margin: 0 5%;
  padding: 12% 0;
}
.background .bwrapper .bcontainer .bflex {
  display: flex;
  align-items: center;
}
.background .bwrapper .bcontainer .bflex .dennisImage {
  width: 35%;
  padding-left: 5%;
}
.background .bwrapper .bcontainer .bflex .dennisImage .img-blk-wrapper {
  width: 270px;
  height: 270px;
  margin-right: 40px;
  padding: 18px;
}
.background .bwrapper .bcontainer .bflex .dennisImage .img-blk-wrapper .img-blk {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(132deg, #6f66be, #6c4ea5);
}
.background .bwrapper .bcontainer .bflex .dennisImage .img-blk-wrapper .img-blk img {
  margin: 0 auto;
  margin-left: 40px;
  margin-bottom: -18px;
  border-radius: 0;
  width: auto;
  max-width: 85%;
  height: auto;
  position: absolute;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}
.background .bwrapper .bcontainer .bflex .dennisImage .img-blk-wrapper .img-blk::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: -18px;
  top: -18px;
  background: #a5a5ff;
  z-index: -1;
}
.background .bwrapper .bcontainer .bflex .dennisImage .img-blk-wrapper .img-blk::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  right: -18px;
  bottom: -18px;
  background: #9974ff;
  z-index: 1;
}
.background .bwrapper .bcontainer .bflex .bTexts {
  width: 60%;
}
.background .bwrapper .bcontainer .bflex .bTexts .btTitle {
  color: var(--text-primary);
  white-space: nowrap;
  margin-bottom: 20px;
  position: relative;
  letter-spacing: 1.5px;
  font-weight: 400;
  text-align: right;
  padding-right: 70px;
}
.background .bwrapper .bcontainer .bflex .bTexts .btTitle .secCounter {
  position: absolute;
  bottom: 0;
  right: 50px;
  padding-bottom: 2px;
  padding-right: 20px;
  color: aqua;
  font-size: 17px;
}
.background .bwrapper .bcontainer .bflex .bTexts .btTitle::before {
  background-color: var(--text-primary);
  content: "";
  display: block;
  height: 1px;
  margin-left: 20px;
  position: relative;
  bottom: -20px;
  width: 50%;
  right: -100px;
}
.background .bwrapper .bcontainer .bflex .bTexts .backtexts {
  color: var(--text-primary);
  font-size: 1.2rem;
  font-weight: 200;
}
.background .bwrapper .bcontainer .bflex .bTexts .backtexts::-moz-selection {
  background: #221427;
}
.background .bwrapper .bcontainer .bflex .bTexts .backtexts::selection {
  background: #221427;
}

@media (max-width: 768px) {
  .background {
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
  }
  .bflex {
    display: flex;
    flex-direction: column;
  }
  .bflex .dennisImage {
    width: 100% !important;
    margin-bottom: 30px;
    position: relative;
    height: 350px;
  }
  .bflex .dennisImage .img-blk-wrapper {
    margin-right: 0px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .bflex .bTexts {
    width: 100% !important;
  }
  .bflex .bTexts .btTitle::before {
    display: none !important;
  }
}/*# sourceMappingURL=background.css.map */