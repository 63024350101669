.login {
  min-height: 100vh;
}

/*=-=-=-=-=-=auth page=-=-=-=-=-=-=*/
.logincontact {
  height: 100vh;
  position: relative;
}
@media (max-width: 480px) {
  .logincontact {
    margin: 10px;
  }
}
.logincontact .contact-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.logincontact .contact-wrapper .contact-header {
  text-align: center;
  font-size: 4rem;
  margin-bottom: 15px;
  color: var(--text-primary);
}
@media (max-width: 480px) {
  .logincontact .contact-wrapper .contact-header {
    font-size: 2.5rem;
  }
}
.logincontact .contact-wrapper .formNotify {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  background-color: #ff7c7c;
  border-left: 5px solid red;
  width: 50%;
  margin: 0 auto;
}
.logincontact .contact-wrapper .formNotifySucc {
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  background-color: #7cff87;
  border-left: 5px solid #00ff88;
  width: 50%;
  margin: 0 auto;
}
.logincontact .contact-wrapper .contFormSec {
  margin-left: 25px;
}
@media (max-width: 480px) {
  .logincontact .contact-wrapper .contFormSec {
    margin: 0 5px;
  }
}
@media (max-width: 480px) {
  .logincontact .contact-wrapper .contFormSec .login-form {
    margin: 5%;
  }
}
.logincontact .contact-wrapper .contFormSec form h3 {
  color: var(--text-primary);
}
.logincontact .contact-wrapper .contFormSec form .cont-group {
  margin-bottom: 40px;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp {
  position: relative;
  width: 94%;
  margin: 5px 0px;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText {
  width: 100%;
  outline: none;
  border: 1px solid rgb(211, 211, 211);
  padding: 10px 13px;
  box-shadow: none !important;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  color: #444452;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:not([value=""]) {
  background-color: rgb(255, 219, 166);
}
.logincontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 10px;
  transition: 0.2s ease all;
  opacity: 0.5;
  color: rgb(95, 95, 95);
}
.logincontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:not([value=""]) ~ .floating-label,
.logincontact .contact-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:focus ~ .floating-label {
  top: 0px;
  left: 8px;
  font-size: 13px;
  opacity: 1;
  color: var(--text-primary);
}
.logincontact .contact-wrapper .contFormSec form .cont-group .cont-gr-flex {
  width: 97%;
  display: flex;
  justify-content: space-between;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .cont-gr-flex .contPut {
  width: 46%;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
  margin: 15px 0px;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row {
  width: 97%;
  position: relative;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px dashed var(--color);
  border-radius: 5px;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content {
  display: flex;
  align-items: center;
  position: relative;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .icon {
  margin-right: 15px;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .icon img {
  max-width: 100%;
  height: auto;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label {
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 22px;
  color: var(--theme-color);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 800px;
  overflow-x: scroll;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label::-webkit-scrollbar {
  scrollbar-color: rgb(35, 42, 46);
  width: 5px;
  max-height: 3px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0);
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #eaeaea;
  background-color: #505050;
  margin-right: 3px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list:hover {
  background-color: #797979;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap {
  display: flex;
  align-items: center;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap .button {
  background: #2ac6ff;
  border-radius: 4px;
  padding: 9px 23px 11px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
}
.logincontact .contact-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .file-field {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 70%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.logincontact .contact-wrapper .contFormSec form p span {
  color: #2ac6ff;
  cursor: pointer;
}
.logincontact .contact-wrapper .contFormSec form .resetting-pass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
.logincontact .contact-wrapper .contFormSec form .resetting-pass .contact-btn {
  border-radius: 4px;
  padding: 13px 54px 15px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
}
.logincontact .contact-wrapper .contFormSec form .resetting-pass .contact-btn:hover {
  color: rgb(109, 216, 255);
  background-color: rgb(233, 233, 233);
  transition: 0.3s;
}
.logincontact .contact-wrapper .contFormSec form .resetting-pass p a {
  text-decoration: none;
  color: #2ac6ff;
}

.hide-activity {
  display: none !important;
}

@-webkit-keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.rainbow {
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;
  color: #000 !important;
}
.rainbow::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 500%;
  height: 500%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#399953, #399953), linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33), linear-gradient(#377af5, #377af5);
  -webkit-animation: rotate 4s linear infinite;
          animation: rotate 4s linear infinite;
}
.rainbow::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 2px;
  top: 2px;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  background: white;
  border-radius: 2px;
}/*# sourceMappingURL=login.css.map */