.layout {
  min-height: 100vh;
  width: 100%;
}
.layout .adminheader {
  height: 54px;
  overflow: hidden;
}
.layout .adminmain {
  display: flex;
  width: 100%;
}
.layout .adminmain .adminsidebar {
  width: 15%;
  border-right: 1px solid var(--text-primary);
  overflow-y: scroll;
}
.layout .adminmain .adminsidebar::-webkit-scrollbar {
  width: 5px;
  background-color: #171c28;
}
.layout .adminmain .adminplayarea {
  width: 85%;
  height: calc(100vh - 54px);
  overflow-y: scroll;
}
.layout .adminmain .adminplayarea::-webkit-scrollbar {
  width: 5px;
  background-color: #171c28;
}/*# sourceMappingURL=layout.css.map */