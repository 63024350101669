.layout{
    min-height: 100vh;
    width: 100%;
    .adminheader{
        height: 54px;
        overflow: hidden;
    }
    .adminmain{
        display: flex;
        width: 100%;
        .adminsidebar{
            width: 15%;
            border-right: 1px solid var(--text-primary);
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #171c28;
            }
        }
        .adminplayarea{
            width: calc(100% - 15%);
            height: calc(100vh - 54px);
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 5px;
                background-color: #171c28;
            }
        }
    }
}