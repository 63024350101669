.header {
  margin: 0 8%;
  height: 110px;
  display: flex;
  align-items: center;
}
.header .header-wrapper .bars {
  fill: var(--text-primary);
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 10px;
}
@media (min-width: 480px) {
  .header .header-wrapper .bars {
    display: none;
  }
}
@media (max-width: 480px) {
  .header .header-wrapper .closenav {
    -webkit-animation: close 0.5s forwards;
    animation: close 0.5s forwards;
  }
  @-webkit-keyframes close {
    100% {
      margin-left: -80%;
    }
    0% {
      margin-left: 0%;
    }
  }
  @keyframes close {
    100% {
      margin-left: -80%;
    }
    0% {
      margin-left: 0%;
    }
  }
  .header .header-wrapper .opennav {
    -webkit-animation: open 0.5s forwards;
    animation: open 0.5s forwards;
  }
  @-webkit-keyframes open {
    100% {
      margin-left: 0%;
    }
    0% {
      margin-left: -80%;
    }
  }
  @keyframes open {
    100% {
      margin-left: 0%;
    }
    0% {
      margin-left: -80%;
    }
  }
}
@media (max-width: 480px) {
  .header .header-wrapper .navigation {
    height: 100vh;
    width: 80%;
    background-color: var(--background);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .header .header-wrapper .navigation .timezclose {
    display: unset !important;
  }
}
.header .header-wrapper .navigation .timezclose {
  fill: var(--text-primary);
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 10px;
  display: none;
  position: absolute;
  top: 15px;
  right: 10px;
}
.header .header-wrapper .navigation ul {
  display: flex;
}
@media (max-width: 480px) {
  .header .header-wrapper .navigation ul {
    flex-direction: column;
    margin-top: 30%;
  }
}
.header .header-wrapper .navigation ul .nav-link {
  margin-right: 5%;
}
@media (max-width: 480px) {
  .header .header-wrapper .navigation ul .nav-link {
    margin-top: 40px;
  }
}
.header .header-wrapper .navigation ul .nav-link a {
  padding: 10px;
  font-weight: 200;
  color: var(--text-primary);
  white-space: pre;
}
@media (max-width: 480px) {
  .header .header-wrapper .navigation ul .nav-link a {
    padding: 25px;
    font-size: 2rem;
  }
}
.header .header-wrapper .navigation ul .nav-link a:hover {
  border: 1px solid var(--text-primary);
  border-radius: 10px;
  padding: 9px;
}/*# sourceMappingURL=header.css.map */