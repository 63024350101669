/* Apercu font */
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-Light.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-Light.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-Light.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-Light.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-Light.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-Light.svg#Apercu-Light) format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-LightItalic.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-LightItalic.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-LightItalic.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-LightItalic.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-LightItalic.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-LightItalic.svg#Apercu-LightItalic) format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-Regular.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-Regular.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-Regular.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-Regular.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-Regular.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-Regular.svg#Apercu-Regular) format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-Italic.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-Italic.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-Italic.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-Italic.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-Italic.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-Italic.svg#Apercu-Italic) format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-Medium.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-Medium.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-Medium.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-Medium.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-Medium.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-Medium.svg#Apercu-Medium) format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-MediumItalic.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-MediumItalic.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-MediumItalic.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-MediumItalic.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-MediumItalic.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-MediumItalic.svg#Apercu-MediumItalic) format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-Bold.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-Bold.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-Bold.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-Bold.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-Bold.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-Bold.svg#Apercu-Bold) format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Apercu;
  src: url(../../../public/assets/fonts/apercu/Apercu-BoldItalic.eot);
  src: url(../../../public/assets/fonts/apercu/Apercu-BoldItalic.eot?#iefix) format("embedded-opentype"), url(../../../public/assets/fonts/apercu/Apercu-BoldItalic.woff2) format("woff2"), url(../../../public/assets/fonts/apercu/Apercu-BoldItalic.woff) format("woff"), url(../../../public/assets/fonts/apercu/Apercu-BoldItalic.ttf) format("truetype"), url(../../../public/assets/fonts/apercu/Apercu-BoldItalic.svg#Apercu-BoldItalic) format("svg");
  font-weight: 700;
  font-style: italic;
}
/*
 *font-family: Apercu,"Source Sans Pro",system,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Helvetica,Arial,sans-serif;
*/
/* color schemes */
/*
-- #272727 = darkgray
--#09090c = darkblue
--#dee0ff = whiteblue
--#0d0014 = darkpurle

*/
/* __colors__ => theme white
* text-color: #444452
* background-color: #fff
*/
* {
  padding: 0;
  margin: 0;
}

html,
body {
  border-style: border-box;
  font-family: Apercu, source sans pro, system, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  overflow-x: hidden !important;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
  position: relative;
  z-index: 0 !important;
  scroll-behavior: smooth;
  line-height: 1.5;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

:root {
  --background: #fff;
  --text-primary: #444452;
  --back-boxes: rgb(223, 220, 255);
  --back-related: #F3F8FF;
}

[data-theme=light] {
  --background: #ffffff;
  --text-primary: #444452;
  --back-related: #98b2dd;
  --toggle-transform: translate(0);
  transition: 0.5s;
}

[data-theme=dark] {
  --background: #171c28;
  --text-primary: rgb(230, 230, 230);
  --back-related: #1e304d;
  --toggle-transform: translate(120%);
  transition: 0.5s;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #171c28;
}

@media (max-width: 600px) {
  * {
    -ms-overflow-style: none;
  }
  body::-webkit-scrollbar {
    scrollbar-color: transparent transparent;
  }
}
body::-webkit-scrollbar-thumb {
  background-color: #666;
  outline: 1px solid rgb(82, 82, 82);
  border-radius: 30px;
}

.App {
  background-color: var(--background);
  transition: all 0.2s ease-in-out 0s;
}

.contents {
  transition: all 0.2s ease-in-out 0s;
  margin-right: auto;
  margin-left: auto;
}

.lightMode, .darkMode {
  stroke: var(--text-primary);
  height: 17px;
  width: 17px;
}

.themeControls {
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  margin-top: 30px;
  margin-right: 30px;
  display: flex;
  align-items: center;
}
.themeControls .themeToggle {
  height: 25px;
  width: 50px;
  border-radius: 30px;
  background-color: rgb(156, 156, 156);
  margin: 0 10px;
  position: relative;
  cursor: pointer;
}
.themeControls .themeToggle .toggleButton {
  height: 21px;
  width: 21px;
  margin: 2px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  transform: var(--toggle-transform);
  transition: all 0.2s ease-in-out 0s;
}

.hidecontents {
  display: none !important;
}

.sCounter, .secCounter, .pCounter, .eCounter {
  font-family: Inconsolata, monospace !important;
}

.cookieConsent {
  width: 190px;
  background: url("../../images/cookie-bg.png") center center/100% 100% no-repeat;
  position: fixed;
  right: 0px;
  padding: 14px 64px 20px 20px;
  z-index: 98;
  left: 16px;
  bottom: 16px !important;
}

.cookieText {
  font-size: 17px;
  line-height: 20px;
  margin-bottom: 15px;
  color: rgb(61, 61, 61);
  letter-spacing: 0.1px;
}

.cookiehiglight {
  font-style: italic;
  font-weight: 700;
}

.privacyPolicy {
  text-decoration: underline;
}

.button {
  font-size: 14px;
  line-height: 15px;
  padding: 9px 22px 10px;
  border: 1px solid rgb(0, 0, 0);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1.5px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  background: transparent;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
}/*# sourceMappingURL=globals.css.map */