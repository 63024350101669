/*=-=-=-=-=-=contact-page page=-=-=-=-=-=-=*/
.contact-page {
  min-height: 100vh;
  position: relative;
}
@media (max-width: 480px) {
  .contact-page {
    margin: 0 5px;
  }
}
.contact-page a .homeIcon {
  position: absolute;
  height: 25px;
  width: 25px;
  top: 3%;
  left: 3%;
  fill: #fff;
  cursor: pointer;
}
.contact-page .contact-page-wrapper {
  width: 60%;
  padding: 20px;
  margin: 0 auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
@media (max-width: 480px) {
  .contact-page .contact-page-wrapper {
    width: unset;
  }
}
.contact-page .contact-page-wrapper .contact-page-header {
  text-align: center;
  font-size: 6rem;
  color: var(--text-primary);
}
@media (max-width: 480px) {
  .contact-page .contact-page-wrapper .contact-page-header {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
.contact-page .contact-page-wrapper .formNotifySucc {
  padding: 10px;
  width: 95%;
  color: var(--text-primary);
  background-color: #34ff9a;
  border-left: 7px solid #009431;
  margin-bottom: 20px;
}
.contact-page .contact-page-wrapper .formNotify {
  padding: 10px;
  width: 95%;
  color: var(--text-primary);
  background-color: #ff556b;
  border-left: 7px solid #940000;
  margin-bottom: 20px;
}
.contact-page .contact-page-wrapper .contFormSec {
  padding-top: 3rem;
  margin-left: 25px;
}
@media (max-width: 480px) {
  .contact-page .contact-page-wrapper .contFormSec {
    margin-left: -10px;
    padding-top: 1rem;
  }
}
.contact-page .contact-page-wrapper .contFormSec form h3 {
  color: var(--text-primary);
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group {
  margin-bottom: 40px;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .user-input-wrp {
  position: relative;
  width: 94%;
  margin: 5px 0px;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .user-input-wrp .inputText {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  max-height: 200px;
  outline: none;
  border: 1px solid rgb(211, 211, 211);
  padding: 10px 13px;
  box-shadow: none !important;
  border-radius: 5px;
  background-color: rgb(235, 235, 235);
  display: unset !important;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:not([value=""]) {
  background-color: rgb(255, 219, 166);
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .user-input-wrp .floating-label {
  position: absolute;
  pointer-events: none;
  top: 30px;
  left: 10px;
  transition: 0.2s ease all;
  opacity: 0.5;
  color: rgb(95, 95, 95);
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:not([value=""]) ~ .floating-label,
.contact-page .contact-page-wrapper .contFormSec form .cont-group .user-input-wrp .inputText:focus ~ .floating-label {
  top: 0px;
  left: 8px;
  font-size: 13px;
  opacity: 1;
  color: var(--text-primary);
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .cont-gr-flex {
  width: 97%;
  display: flex;
  justify-content: space-between;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .cont-gr-flex .contPut {
  width: 46%;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row {
  display: flex;
  margin-bottom: 22px;
  justify-content: space-between;
  margin: 15px 0px;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row {
  width: 98%;
  position: relative;
}
@media (max-width: 480px) {
  .contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row {
    width: 100%;
  }
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief {
  display: flex;
  padding: 18px 20px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px dashed var(--text-primary);
  border-radius: 5px;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content {
  display: flex;
  align-items: center;
  position: relative;
  width: 80%;
}
@media (max-width: 480px) {
  .contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content {
    width: 100%;
  }
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .icon {
  margin-right: 15px;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .icon img {
  max-width: 100%;
  height: auto;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label {
  font-size: 16px;
  letter-spacing: -0.17px;
  line-height: 22px;
  color: var(--text-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 800px;
  overflow-x: scroll;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label::-webkit-scrollbar {
  scrollbar-color: rgb(35, 42, 46);
  width: 5px;
  max-height: 3px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0);
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list {
  padding: 8px 15px;
  border-radius: 23px;
  font-size: 0.9rem;
  text-decoration: none;
  color: #eaeaea;
  background-color: #505050;
  margin-right: 3px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  white-space: nowrap;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .file-content .content-label .filenames-list:hover {
  background-color: #797979;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap {
  display: flex;
  align-items: center;
}
@media (max-width: 480px) {
  .contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap {
    display: none;
  }
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .project_brief .row-wrap .button {
  background: #2ac6ff;
  border-radius: 4px;
  padding: 9px 23px 11px;
  font-size: 16px;
  letter-spacing: 1.6px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-weight: 500;
  z-index: unset !important;
}
.contact-page .contact-page-wrapper .contFormSec form .cont-group .input-field-row .input-file-row .file-field {
  height: 100%;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
  cursor: pointer;
}
.contact-page .contact-page-wrapper .contFormSec form .contact-page-btn {
  border-radius: 4px;
  padding: 13px 54px 15px;
  background-color: #2ac6ff;
  border: 1px solid #2ac6ff;
  position: relative;
  overflow: hidden;
  z-index: 1;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  align-items: center;
  transition: color 0.15s ease-in-out 0s;
  display: inline-block;
  margin-bottom: 4rem;
  margin-top: 2rem;
}
.contact-page .contact-page-wrapper .contFormSec form .contact-page-btn:hover {
  color: rgb(109, 216, 255);
  background-color: rgb(233, 233, 233);
  transition: 0.3s;
}/*# sourceMappingURL=cont.css.map */