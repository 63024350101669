.background{
    height: 100vh;
    .bwrapper{
        .bcontainer{
            margin: 0 5%;
            padding: 12% 0;
            .bflex{
                display: flex;
                align-items: center;
                .dennisImage{
                    width: 35%;
                    padding-left: 5%;
                    .img-blk-wrapper{
                        width: 270px;
                        height: 270px;
                        margin-right: 40px;
                        padding: 18px;
                        .img-blk{
                            position: relative;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(132deg,#6f66be,#6c4ea5);
                            img{
                                margin: 0 auto;
                                margin-left: 40px;
                                margin-bottom: -18px;
                                border-radius: 0;
                                width: auto;
                                max-width: 85%;
                                height: auto;
                                position: absolute;
                                bottom: 0;
                                object-fit: cover;
                                z-index: 2;
                            }
                        }
                        .img-blk::before{
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: -18px;
                            top: -18px;
                            background: #a5a5ff;
                            z-index: -1;
                        }
                        .img-blk::after{
                            content: "";
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            right: -18px;
                            bottom: -18px;
                            background: #9974ff;
                            z-index: 1;
                        }
                    }
                }
                .bTexts{
                    width: 60%;
                    .btTitle{
                        color: var(--text-primary);
                        white-space: nowrap;
                        margin-bottom: 20px;
                        position: relative;
                        letter-spacing: 1.5px;
                        font-weight: 400;
                        text-align: right;
                        padding-right: 70px;
                        .secCounter{
                            position: absolute;
                            bottom: 0;
                            right: 50px;
                            padding-bottom: 2px;
                            padding-right: 20px;
                            color: aqua;
                            font-size: 17px;
                        }
                    }
                    .btTitle::before{
                        background-color: var(--text-primary);
                        content: "";
                        display: block;
                        height: 1px;
                        margin-left: 20px;
                        position: relative;
                        bottom: -20px;
                        width: 50%;
                        right: -100px;
                    }
                    .backtexts{
                        color: var(--text-primary);
                        font-size: 1.2rem;
                        font-weight: 200;
                    }
                    .backtexts::selection {
                        background: #221427;
                    }
                }
            }
        }
    }
}

//responsiveness
@media(max-width: 768px){
    .background{
        height: min-content;
    }
    .bflex{
        display: flex;
        flex-direction: column;
        .dennisImage{
            width: 100% !important;
            margin-bottom: 30px;
            position: relative;
            height: 350px;
            .img-blk-wrapper{
                margin-right: 0px !important;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
        .bTexts{
            width: 100% !important;
            .btTitle::before{
                display: none !important;
            }
        }
    }
}