.adminSidebar {
  width: 100%;
  padding: 20px 0;
}
.adminSidebar .adminnavigation {
  width: 100%;
}
.adminSidebar .adminnavigation div {
  color: var(--text-primary);
  display: flex;
  margin-bottom: 10px;
}
.adminSidebar .adminnavigation div::before {
  content: "";
  display: block;
  height: 1px;
  width: 10px;
  background-color: #fff;
  margin-top: 12px;
}
.adminSidebar .adminnavigation div span {
  margin-left: 5px;
}
.adminSidebar .adminnavigation ul {
  width: 100%;
}
.adminSidebar .adminnavigation ul li {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 5px 10px;
  padding-left: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.adminSidebar .adminnavigation ul li .navIcs {
  fill: var(--text-primary);
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: 3px;
}
.adminSidebar .adminnavigation ul li span {
  color: var(--text-primary);
  font-size: 1rem;
}
.adminSidebar .adminnavigation ul li:hover {
  border: 1px solid var(--text-primary);
  border-radius: 10px;
  padding: 4px 9px;
}/*# sourceMappingURL=sidebar.css.map */