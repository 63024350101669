.contact {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  position: relative;
}
.contact .conwrapper .concontainer {
  margin: 0 5%;
}
.contact .conwrapper .concontainer .conflex {
  width: 100%;
  position: relative;
}
.contact .conwrapper .concontainer .conflex .conTitle {
  position: relative;
  text-align: center;
  color: var(--text-primary);
  letter-spacing: 1.5px;
  font-weight: 400;
  font-size: 20px;
}
.contact .conwrapper .concontainer .conflex .conTitle .conCounter {
  position: absolute;
  bottom: 0;
  padding-bottom: 2px;
  margin-right: 20px;
  color: aqua;
  font-size: 12px;
}
.contact .conwrapper .concontainer .conflex .constitle {
  text-align: center;
  color: var(--text-primary);
  font-size: 5rem;
  font-weight: 600;
  margin-top: 10px;
}
@media (max-width: 480px) {
  .contact .conwrapper .concontainer .conflex .constitle {
    font-size: 3rem;
  }
}
.contact .conwrapper .concontainer .conflex .contexts {
  text-align: center;
  color: var(--text-primary);
  font-weight: 100;
  font-size: 1.3rem;
}
.contact .conwrapper .concontainer .conflex .contbtnP {
  margin-top: 20px;
  width: 100%;
  text-align: center;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}
.contact .conwrapper .concontainer .conflex .contbtnP .contactb {
  color: var(--text-primary);
  padding: 10px 30px;
  border-radius: 5px;
  border: 1px solid aqua;
  transition: 0.2s;
  line-height: 1;
  cursor: pointer;
}/*# sourceMappingURL=contact.css.map */