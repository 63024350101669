.landing {
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  position: relative;
}
.landing .lcontainer {
  margin: 0 5%;
}
.landing .lcontainer .lwrapper {
  padding-top: 0px;
}
.landing .lcontainer .lwrapper .lflex {
  display: flex;
}
.landing .lcontainer .lwrapper .lflex .ltexts {
  width: 45%;
  margin-left: 5%;
  margin-top: 5%;
}
@media (min-width: 1400px) {
  .landing .lcontainer .lwrapper .lflex .ltexts {
    margin-top: 10% !important;
  }
}
.landing .lcontainer .lwrapper .lflex .ltexts .lcachy {
  font-size: 1.75rem;
  color: var(--text-primary);
}
.landing .lcontainer .lwrapper .lflex .ltexts .lcachy img {
  margin: 0 15px;
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
}
.landing .lcontainer .lwrapper .lflex .ltexts .lcachy .wave {
  -webkit-animation-name: wave-animation;
          animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s; /* Change to speed up or slow down */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}
@-webkit-keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
.landing .lcontainer .lwrapper .lflex .ltexts .myname {
  color: var(--text-primary);
  margin-top: 20px;
  font-size: 5.375rem;
  line-height: 5rem;
  letter-spacing: -0.25rem;
  font-weight: 500;
}
.landing .lcontainer .lwrapper .lflex .ltexts .lintrotxts {
  color: var(--text-primary);
  margin-top: 10px;
  font-size: 1.8rem;
  margin-bottom: 50px;
  font-weight: 100;
}
@media (max-width: 1400px) {
  .landing .lcontainer .lwrapper .lflex .ltexts .lintrotxts {
    font-size: 1.375rem;
    line-height: 1.64;
    letter-spacing: -0.015em;
  }
}
.landing .lcontainer .lwrapper .lflex .ltexts .lintrotxts::-moz-selection {
  background: #221427;
}
.landing .lcontainer .lwrapper .lflex .ltexts .lintrotxts::selection {
  background: #221427;
}
.landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks {
  margin-top: 40px;
  display: flex;
  align-content: center;
  align-items: center;
}
.landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks .git {
  margin-right: 15px;
}
@media (max-width: 480px) {
  .landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks .git {
    width: 100% !important;
  }
}
.landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks .git a {
  border: 0;
  background-color: rgb(0, 0, 0);
  padding: 15px 40px;
  border-radius: 5px;
  position: relative;
  color: #fff !important;
  text-decoration: none;
}
.landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks .git a .pIcons {
  font-size: 20px;
  margin-bottom: -5px !important;
}
.landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks .dresume .dresumeLink {
  border: 0;
  background-color: rgb(0, 0, 0);
  padding: 15px 40px;
  border-radius: 5px;
  position: relative;
  color: #fff !important;
  text-decoration: none;
}
.landing .lcontainer .lwrapper .lflex .ltexts .pouterLinks .dresume .dresumeLink .pIcons {
  font-size: 20px;
  margin-bottom: -5px !important;
}
.landing .lcontainer .lwrapper .lflex .limages {
  width: 50%;
}
.landing .lcontainer .lwrapper .lflex .limages .limageWrapper {
  width: 60%;
  margin-top: -80px;
  padding-left: 70px;
}
.landing .lcontainer .lwrapper .lflex .limages .limageWrapper .denoo {
  width: 100%;
  height: auto;
  transition: 0.5s;
  -webkit-mask-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
.landing .callToActionScroll {
  -webkit-animation: calltoaction 1s ease-out 0s infinite alternate none running;
  animation: calltoaction 1s ease-out 0s infinite alternate none running;
  fill: red;
  font-size: 10px !important;
  margin: 0 5%;
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  text-align: center !important;
  width: 90%;
  z-index: 10;
}
.landing .callToActionScroll .MuiSvgIcon-root path {
  width: 20px !important;
}
@-webkit-keyframes calltoaction {
  0% {
    fill: red;
    margin-bottom: 20px;
  }
  to {
    fill: #ff0;
    margin-bottom: 5px;
  }
}
@keyframes calltoaction {
  0% {
    fill: red;
    margin-bottom: 20px;
  }
  to {
    fill: #ff0;
    margin-bottom: 5px;
  }
}
.landing .hidecallToActionScroll {
  display: none !important;
}

@media (max-width: 1000px) {
  .limages {
    width: 50%;
  }
  .limages .limageWrapper {
    width: 70% !important;
    margin-top: 30px !important;
    padding-left: 70px;
  }
}
@media (max-width: 768px) {
  .ltexts {
    width: 100% !important;
  }
  .ltexts .lcachy {
    margin-top: 10px !important;
    font-size: 1.5rem;
  }
  .ltexts .myname {
    margin-top: 20px !important;
    font-size: 50px;
  }
  .ltexts .lintrotxts {
    font-size: 1.75rem !important;
    margin-bottom: 100px;
    font-weight: lighter;
  }
  .limages {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .ltexts {
    width: 100% !important;
  }
  .ltexts .lcachy {
    margin-top: 50px;
    font-size: 1.5rem;
  }
  .ltexts .myname {
    margin-top: 40px !important;
    font-size: 50px;
  }
  .ltexts .lintrotxts {
    font-size: 1.75rem !important;
    margin-bottom: 100px;
  }
  .limages {
    display: none !important;
  }
}
@media (max-width: 420px) {
  .lintrotxts {
    margin-bottom: 60px !important;
  }
  .pouterLinks {
    display: flex;
    flex-direction: column !important;
  }
  .pouterLinks .git {
    margin-right: 0px !important;
  }
  .pouterLinks .git a {
    padding: 15px 35% !important;
  }
  .pouterLinks .dresume {
    margin-top: 50px;
  }
  .pouterLinks .dresume a {
    padding: 15px 33.5% !important;
  }
}/*# sourceMappingURL=landing.css.map */