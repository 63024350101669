.skills{
    height: min-content;
    .swrapper{
        .scontainer{
            margin: 0 10%;
            .sflex{
                display: flex;
                width: 100%;
                .sTexts{
                    width: 50%;
                    .sTitle{
                        color: var(--text-primary);
                        white-space: nowrap;
                        display: flex;
                        margin-bottom: 20px;
                        position: relative;
                        letter-spacing: 1.5px;
                        font-weight: 400;
                        .sCounter{
                            position: absolute;
                            bottom: 0;
                            padding-bottom: 2px;
                            padding-right: 20px;
                            color: aqua;
                            font-size: 17px;
                        }
                    }
                    .sTitle::after{
                        background-color: var(--text-primary);
                        content: "";
                        display: block;
                        height: 1px;
                        margin-bottom: -20px;
                        margin-left: 20px;
                        position: relative;
                        bottom: -20px;
                        width: 50%;
                    }
                    .skillsTexts{
                        color: var(--text-primary);
                        font-size: 1.4rem;
                        font-weight: 200;
                    }
                    .snav{
                        display: flex;
                        margin-left: 40px;
                        ul{
                            margin-right: 50px;
                            margin-top: 30px;
                            h2{
                                color: var(--text-primary);
                                padding: 0;
                                margin-bottom: -10px;
                                margin-left: -10px;
                                font-size: 22px;
                            }
                            li{
                                position: relative;
                                padding-top: 20px;
                                display: block;
                                color: var(--text-primary);
                                font-size: 18px;
                                font-weight: 200;
                            }
                            li:after{
                                content: "▹";
                                position: absolute;
                                left: 0px;
                                color: aqua;
                                font-size: 20px;
                                line-height: 12px;
                                margin-left: -20px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .sImages{
                    width: 50%;
                    .codeplay{
                        transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
                        width: 100%;
                        height: 570px;
                        margin-top: 50px;
                        background-color: transparent;
                        position: relative;
                        display: flex;
                        -moz-box-align: center;
                        align-items: center;
                        -moz-box-pack: center;
                        justify-content: center;
                        transition-duration: .10s;
                        margin-left: 40px;
                        .top-left-bg{
                            width: calc(100% - 80px);
                            height: calc(100% - 80px);
                            position: absolute;
                            background: rgb(255, 242, 217) none repeat scroll 0% 0%;
                            top: 20px;
                            left: 20px;
                            z-index: -2;
                        }
                        .top-right-bg{
                            width: calc(100% - 80px);
                            height: calc(100% - 80px);
                            position: absolute;
                            background: rgb(255, 242, 217) none repeat scroll 0% 0%;
                            bottom: 20px;
                            right: 20px;
                            z-index: -1;
                        }
                        .img-center-block{
                            width: calc(100% - 80px);
                            height: calc(100% - 80px);
                            background: rgba(0, 0, 0, 0) linear-gradient(312deg, rgb(255, 227, 169), rgb(255, 229, 174)) repeat scroll 0% 0%;
                            position: relative;
                            .img-wrapper{
                                position: absolute !important;
                                bottom: 0px;
                                left: 0px;
                                right: 0px;
                                height: 100%;
                                display: block;
                                overflow: hidden;
                                max-width: 499px;
                                margin: 0px auto;
                                .codeplay-area{
                                    background-color: rgb(11, 24, 58);
                                    position: absolute;
                                    top: 10px;
                                    left: 10px;
                                    width: 87%;
                                    height: 90%;
                                    object-fit: cover;
                                    object-position: center center;
                                    opacity: 1;
                                    padding: 20px;
                                    padding-top: 10px;
                                    transition: opacity 500ms ease 0s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 768px){
    .scontainer{
        margin: 0px 5% !important;
    }
    .sflex{
        flex-direction: column;
        .sTexts{
            width: 100% !important;
            .sTitle::after{
                display: none !important;
            }
            .snav{
                margin-left: 20px !important;
            }
        }
        .sImages{
            width: 110% !important;
            margin: 0px -5%;
            .codeplay{
                margin-left: 0px !important;
                .top-left-bg{
                    display: none !important;
                }
                .img-center-block{
                    position: unset !important;
                    .img-wrapper{
                        .codeplay-area{
                            top: 5px !important;
                            left: 5px !important;
                        }
                    }
                }
            }
        }
    }
}
@media(max-width: 480px){
    .sTexts{
        .snav{
            margin-left: 10px !important;
            ul{
                margin-right: 30px !important;
            }
        }
    }
}