@import url("https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Inconsolata:wght@200;300;400&family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,500&display=swap");
.experience {
  height: auto;
}
.experience .ewrapper .econtainer {
  margin: 6% 5%;
  margin-bottom: 10%;
}
.experience .ewrapper .econtainer .eflex .eTexts .eTitle {
  position: relative;
  text-align: center;
  color: var(--text-primary);
  letter-spacing: 1.5px;
  font-weight: 400;
}
.experience .ewrapper .econtainer .eflex .eTexts .eTitle .eCounter {
  position: absolute;
  bottom: 0;
  padding-bottom: 2px;
  margin-right: 20px;
  color: aqua;
  font-size: 17px;
}
.experience .ewrapper .econtainer .eflex .eTexts .experiences .exp {
  margin: 5% 0;
  margin-left: 10%;
}
.experience .ewrapper .econtainer .eflex .eTexts .experiences .exp .eOneTitle {
  color: var(--text-primary);
  letter-spacing: 1.2px;
  font-weight: 400;
  margin-bottom: 10px;
}
.experience .ewrapper .econtainer .eflex .eTexts .experiences .exp .eOneTexts {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.experience .ewrapper .econtainer .eflex .eTexts .experiences .exp .eOneTexts p {
  color: var(--text-primary);
  width: 70%;
  font-weight: 100;
}
.experience .ewrapper .econtainer .eflex .eTexts .experiences .exp .eOneTexts .eduration {
  color: var(--text-primary);
  width: 20%;
  font-family: Inconsolata, monospace;
}

@media (max-width: 480px) {
  .exp {
    margin-left: 0px !important;
  }
  .exp .eOneTitle {
    font-size: 20px;
  }
  .exp .eOneTexts {
    width: 100% !important;
    flex-direction: column;
  }
  .exp .eOneTexts p {
    width: 100% !important;
  }
  .exp .eOneTexts .eduration {
    width: 100% !important;
    margin-top: 10px;
  }
}/*# sourceMappingURL=experience.css.map */