.dashHome{
    width: 100%;
    height: calc(100vh - 54px);
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 5px;
        background-color: #171c28;
    }
    .dashHomeContainer{
        padding: 10px;
        width: calc(100% - 10%);
        margin: 20px 4%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: minmax(250px, auto);
        gap: 1rem;
        .dashHWidgets{
            min-height: 250px;
            border: 1px solid var(--text-primary);
            border-radius: 10px;
            padding: 10px;
            &:nth-child(1){
                background-image: url(https://www.transparenttextures.com/patterns/cubes.png), linear-gradient(to right top, #cf4af3, #e73bd7, #f631bc, #fd31a2, #ff3a8b, #ff4b78, #ff5e68, #ff705c, #ff8c51, #ffaa49, #ffc848, #ffe652);
            }
            &:nth-child(3){
                grid-row: span 2;
                grid-column: span 2;
                background-image: url('../../../images/black-felt.png'), linear-gradient(to right top, #268f83, #30cebb);
            }
        }
    }
}